<template>
  <transition name="modal">
    <div class="modal fade" @click="this.$modal(id)" :id="id">
      <div class="modal-dialog" :class="`modal-${size}`" role="document" @click.stop>
        <div class="modal-content">
          <a @click="this.$modal(id)" class="close" data-bs-dismiss="modal"><em class="bi bi-x"></em></a>
          <div class="modal-body modal-body-md">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['id', 'size'],
};
</script>