import {createRouter, createWebHistory} from "vue-router";
import store from "./store";
import {GlobalMethods} from "./global/main.js";

const routes = [
    {
        path: "/:ref?",
        name: "Home",
        component: () => import("./views/Home.vue"),
        meta: {},
    },
    {
        path: "/reset/:key",
        name: "reset",
        component: () => import("./views/Password.vue"),
        meta: {},
    },
    {
        path: "/affiliate/:id?",
        name: "Affiliate program",
        component: () => import("./views/Affiliate.vue"),
        meta: {auth: true},
    },
    {
        path: "/news/:id?",
        name: "News",
        component: () => import("./views/News.vue"),
        meta: {},
    },
    {
        path: "/wallet/:act?/:mod?",
        name: "Wallet",
        component: () => import("./views/Wallet.vue"),
        meta: {auth: true},
    },
    {
        path: "/quests/:id?",
        name: "quests",
        component: () => import("./views/Quests.vue"),
        meta: {auth: true},
    },
    {
        path: "/panel",
        name: "Управление",
        component: () => import("./views/panel/main.vue"),
        meta: {auth: true, prava: 5, panel: true},
    },
    {
        path: "/panel/category",
        name: "Управление/Категории",
        component: () => import("./views/panel/category.vue"),
        meta: {auth: true, prava: 5, panel: true},
    },
    {
        path: "/panel/collections",
        name: "Управление/collections",
        component: () => import("./views/panel/collections.vue"),
        meta: {auth: true, prava: 5, panel: true},
    },
    {
        path: "/panel/tags",
        name: "Управление/tags",
        component: () => import("./views/panel/tags.vue"),
        meta: {auth: true, prava: 5, panel: true},
    },
    {
        path: "/panel/games",
        name: "Управление/games",
        component: () => import("./views/panel/games.vue"),
        meta: {auth: true, prava: 5, panel: true},
    },
    {
        path: "/panel/items",
        name: "Управление/items",
        component: () => import("./views/panel/items.vue"),
        meta: {auth: true, prava: 5, panel: true},
    },
    {
        path: "/panel/users",
        name: "Управление/users",
        component: () => import("./views/panel/users.vue"),
        meta: {auth: true, prava: 5, panel: true},
    },
    {
        path: "/panel/quests",
        name: "Управление/quests",
        component: () => import("./views/panel/quests.vue"),
        meta: {auth: true, prava: 5, panel: true},
    },
    {
        path: "/panel/slider",
        name: "slider",
        component: () => import("./views/panel/slider.vue"),
        meta: {auth: true, prava: 5, panel: true},
    },
    {
        path: "/panel/events",
        name: "events",
        component: () => import("./views/panel/events.vue"),
        meta: {auth: true, prava: 5, panel: true},
    },
    {
        path: "/panel/traffic",
        name: "traffic",
        component: () => import("./views/panel/traffic.vue"),
        meta: {auth: true, prava: 5, panel: true},
    },
    {
        path: "/panel/about",
        name: "panel/about",
        component: () => import("./views/panel/about.vue"),
        meta: {auth: true, prava: 5, panel: true},
    },
    {
        path: "/panel/privacy",
        name: "panel/privacy",
        component: () => import("./views/panel/privacy.vue"),
        meta: {auth: true, prava: 5, panel: true},
    },
    {
        path: "/panel/news",
        name: "panel/News",
        component: () => import("./views/panel/news.vue"),
        meta: {auth: true, prava: 5, panel: true},
    },
    {
        path: "/store",
        name: "store",
        component: () => import("./views/store/main.vue"),
        meta: {auth: false},
    },
    {
        path: "/store/:id",
        name: "collection",
        component: () => import("./views/store/collection.vue"),
        meta: {auth: false},
    },
    {
        path: "/store/games",
        name: "games",
        component: () => import("./views/store/games.vue"),
        meta: {auth: false},
    },
    {
        path: "/store/my",
        name: "My collection",
        component: () => import("./views/store/my.vue"),
        meta: {auth: true},
    },
    {
        path: "/about/:id?/:id2?",
        name: "about",
        component: () => import("./views/About.vue"),
        meta: {auth: false},
    },
    {
        path: "/privacy",
        name: "privacy",
        component: () => import("./views/Privacy.vue"),
        meta: {auth: false},
    },
    {
        path: "/drop",
        name: "Drop",
        component: () => import("./views/Drop.vue"),
        meta: {auth: false},
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const waitForDataLoaded = () => {
        return new Promise((resolve) => {
            const unwatch = store.watch(
                (state, getters) => getters.user.dataLoaded,
                (newValue) => {
                    if (newValue === true) {
                        unwatch();
                        resolve();
                    }
                },
            );
        });
    };

    // Если данные пользователя уже загружены, продолжаем навигацию
    if (store.getters.user.dataLoaded) {
        return next();
    }

    // В противном случае ждем загрузку данных пользователя
    waitForDataLoaded()
        .then(() => {
            next(); // Продолжаем навигацию после успешной загрузки данных
        })
        .catch((error) => {
            console.error("Ошибка при ожидании загрузки данных пользователя:", error);
            next(false); // Останавливаем навигацию при ошибке
        });
});

router.beforeResolve((to, from, next) => {
    const requireAuth = to.meta.auth;
    const requirePrava = to.meta.prava;

    window.scrollTo(0, 0);
    if ((requireAuth && !store.getters.user.auth) || (store.getters.user && requirePrava && requirePrava > store.getters.user.data.prava)) {
        GlobalMethods.$modal("loginModal");
        next(false);
    } else {
        next();
    }
});

export default router;
