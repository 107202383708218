<template>
    <Modal :id="'loginModal'">
        <div class="nk-block nk-block-middle">
            <div class="nk-block-head">
                <div class="nk-block-head-content">
                    <h5 class="nk-block-title">Sign-In</h5>
                </div>
            </div>
            <form @submit.prevent="submitLogin" id="/auth" class="form-validate">
                <div class="form-group">
                    <div class="form-label-group">
                        <label class="form-label" for="email-address">{{ $t("Email") }}</label>
                    </div>
                    <div class="form-control-wrap">
                        <input autocomplete="email" type="text" class="form-control form-control-lg" name="email" required="" id="email-address" :placeholder="$t('Your email address')" />
                    </div>
                </div>
                <!-- .form-group -->
                <div class="form-group">
                    <div class="form-label-group">
                        <label class="form-label" for="password">{{ $t("Password") }}</label>
                        <a @click="this.$modal('resetPass')" class="link link-primary link-sm">{{ $t("Forgot password") }}</a>
                    </div>
                    <div class="form-control-wrap">
                        <span class="form-icon form-icon-right passcode-switch lg pointer" @click="switchToggle">
                            <i v-show="!passShow" class="bi bi-eye fs-16px"></i>
                            <i v-show="passShow" class="bi bi-eye-slash fs-16px"></i>
                        </span>
                        <input :type="passShow ? 'text' : 'password'" name="password" class="form-control form-control-lg" :placeholder="$t('Your password')" />
                    </div>
                </div>
                <!-- .form-group -->
                <div class="form-group">
                    <button type="submit" class="btn btn-lg btn-dark btn-block">{{ $t("Sign in") }}</button>
                </div>
            </form>
            <div class="text-center pt-4">
                {{ $t("New on our platform?") }} <a @click="this.$modal('regModal')" class="pointer fs-16px">{{ $t("Create an account") }}</a>
            </div>
        </div>
    </Modal>

    <Modal :id="'regModal'">
        <div class="nk-block nk-block-middle">
            <div class="nk-block-head">
                <div class="nk-block-head-content">
                    <h4 class="nk-block-title">{{ $t("Create account") }}</h4>
                </div>
            </div>

            <form @submit.prevent="submitReg" id="/reg" class="form-validate">
                <div class="form-group">
                    <label class="form-label" for="name">{{ $t("Name") }}</label>
                    <div class="form-control-wrap">
                        <input type="text" class="form-control form-control-lg" required="" minlength="2" maxlength="20" name="name" v-model="name" v-model.trim="name" @input="checkName" :placeholder="$t('Enter your name')" />
                        <div class="mt-2" :class="{'text-success': hasName}">4-20 {{ $t("length") }}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label" for="email">{{ $t("Email") }}</label>
                    <div class="form-control-wrap">
                        <input type="text" class="form-control form-control-lg" name="email" required="" minlength="6" maxlength="30" v-model="email" v-model.trim="email" @input="checkEmail" :placeholder="$t('Enter your email')" />
                        <div class="mt-2" :class="{'text-success': hasEmail}">{{ $t("Enter a valid email address.") }}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label" for="password">{{ $t("Password") }}</label>
                    <div class="form-control-wrap">
                        <span class="form-icon form-icon-right passcode-switch lg pointer" @click="switchToggle">
                            <i v-show="!passShow" class="bi bi-eye fs-16px"></i>
                            <i v-show="passShow" class="bi bi-eye-slash fs-16px"></i>
                        </span>
                        <input :type="passShow ? 'text' : 'password'" name="password" class="form-control form-control-lg" v-model="password" v-model.trim="password" @input="checkPassword" required="" :placeholder="$t('Enter your password')" />
                        <div class="mt-2" :class="{'text-success': lenValid}">6 {{ $t("length") }}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label" for="password">{{ $t("Password") }}</label>
                    <div class="form-control-wrap">
                        <input name="password2" type="password" class="form-control form-control-lg" v-model="password2" required="" :placeholder="$t('Enter your password')" />
                        <div class="d-flex justify-content-around wrap-wrap mt-2">
                            <div v-show="password2" :class="passValid ? 'text-success' : 'text-danger'">{{ passwordMatchText }}</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-lg btn-dark btn-block" :disabled="regBtn">{{ $t("Create account") }}</button>
                </div>
            </form>
            <div class="text-center pt-4">
                {{ $t("Already have an account?") }} <a @click="this.$modal('loginModal')" class="fs-16px">{{ $t("Sign in instead") }}</a>
            </div>
        </div>
    </Modal>

    <Modal :id="'resetPass'">
        <div class="nk-block nk-block-middle">
            <div class="nk-block-head">
                <div class="nk-block-head-content">
                    <h5 class="nk-block-title">{{ $t("Reset password") }}</h5>
                    <div>
                        <p>{{ $t("If you forgot your password") }}</p>
                    </div>
                </div>
            </div>
            <form @submit.prevent="submitReset" id="/password" class="form-validate">
                <div class="form-group">
                    <div class="form-label-group">
                        <label class="form-label" for="email-address">{{ $t("Email") }}</label>
                    </div>
                    <div class="form-control-wrap">
                        <input autocomplete="email" type="text" class="form-control form-control-lg" name="email" required="" :placeholder="$t('Your email address')" />
                    </div>
                </div>
                <!-- .form-group -->
                <div class="form-group">
                    <button type="submit" class="btn btn-lg btn-dark btn-block">{{ $t("Send Reset Link") }}</button>
                </div>
            </form>
            <!-- form -->
            <div class="text-center pt-4">
                {{ $t("Already have an account?") }} <a @click="this.$modal('loginModal')" class="fs-16px">{{ $t("Return to login") }}</a>
            </div>
        </div>
    </Modal>
</template>

<script>
export default {
    data() {
        return {
            name: "",
            email: "",
            password: "",
            password2: "",
            lenValid: false,
            hasUpperCase: false,
            hasNumber: false,
            hasSpecialChar: false,
            hasEmail: false,
            hasName: false,
            passShow: false,
            passShow: false,
        };
    },
    computed: {
        passValid() {
            return this.password === this.password2;
        },
        passwordMatchText() {
            return this.passValid ? "" : this.$t("Password mismatch");
        },
        regBtn() {
            return !(this.lenValid && this.passValid && this.name.length >= 4 && this.hasEmail);
        },
    },
    methods: {
        switchToggle() {
            this.passShow = !this.passShow;
        },
        checkPassword() {
            this.lenValid = this.password.length >= 6 && this.password.length <= 30;
        },
        checkEmail() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            this.hasEmail = emailPattern.test(this.email);
        },
        checkName() {
            this.hasName = this.name.length > 3 && this.name.length <= 20;
        },
        async submitLogin(e) {
            const response = await this.$post(e, "submit");
            if (response && response.token) {
                this.$modal("loginModal");
            } else {
                this.$toast.show(this.$t("Incorrect email or password"), {type: "error"});
            }
        },
        async submitReg(e) {
            const response = await this.$post(e, "submit");
            if (response && response.token) {
                this.$modal("regModal");
                this.$router.push("/mint");
            } else {
                this.$toast.show(this.$t(response.error || "error"), {type: "error"});
            }
        },
        async submitReset(e) {
            const response = await this.$post(e, "submit");
            if (response && response.result) {
                this.$toast.show(this.$t("Link sent to your email"), {type: "success"});
            } else if (response && response.error) {
                this.$toast.show(this.$t(response.error || "error"), {type: "error"});
            }
        },
    },
};
</script>
