export default {
    state: {
        userData: {},
        auth: false,
        dataLoaded: false,
        mint: {step: 1, stepTime: 0},
    },
    mutations: {
        SET_LOAD(state) {
            state.dataLoaded = true;
        },
        UPDATE_APP(state, newData) {
            state.app = newData;
        },
        SET_USER(state, userData) {
            state.userData = userData;
            state.auth = true;
            state.dataLoaded = true;
        },
        UPDATE_USER(state, newData) {
            const user = state.userData;
            for (const key in newData) {
                if (newData.hasOwnProperty(key)) {
                    state.userData[key] = newData[key];
                }
            }
            state.dataLoaded = true;
        },
        UPDATE_MINT(state, newData) {
            state.mint = newData;
        },
        OUT_USER(state) {
            state.userData = {};
            state.auth = false;
            state.mint = {step: 1, stepTime: 0};
        },
        SET_AUTH(state, auth) {
            state.auth = auth;
        },
    },
    actions: {
        async load({commit}, newData) {
            commit("SET_LOAD");
        },
        async userData({commit}, newData) {
            commit("SET_USER", newData);
        },
        async updateUser({commit}, newData) {
            commit("UPDATE_USER", newData);
        },
        async mintData({commit}, newData) {
            commit("UPDATE_MINT", newData);
        },
        async userOut({commit}) {
            commit("OUT_USER");
        },
        async authData({commit}, newData) {
            try {
                commit("SET_AUTH", newData);
            } catch (error) {
                console.error("Ошибка при изменении данных:", error);
            }
        },
    },
    getters: {
        user(state) {
            const data = state.userData;
            const auth = state.auth;
            const mint = state.mint;
            const dataLoaded = state.dataLoaded;
            return {data, auth, mint, dataLoaded: dataLoaded};
        },
    },
};
