<template>
    <body class="nk-body dark-mode" >
        <div class="nk-app" v-show="user.dataLoaded">
            <div class="nk-main" >
               <toastr />
               <Navbar />
               <div class="nk-content">
               <router-view />
               </div>
               <Sidebar />
            </div>
        </div>
    </body>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
        };
    },
};
</script>

<style>
@import "assets/css/root.css";
@import "assets/css/libs/bootstrap-icons/font/bootstrap-icons.css";
@import "assets/css/style.css";
@import "assets/css/modal.css";
@import "assets/css/theme.css";
@import "assets/css/slider.css";

</style>
