<template>
    <div class="nk-footer">
        <div>
            <div class="nk-footer-wrap">
                <div class="nk-footer-copyright d-none d-md-block">© <router-link to="/">CyberEthanol</router-link></div>
                <div class="nk-footer-links">
                    <ul class="nav-sm">
                        <li class="nav-item">
                            <div class="d-flex flex-wrap justify-content-center mt5">
                                <a class="footer-link" to=""> Support <em class="icon ni ni-lock-alt"></em></a>
                                <router-link to="/privacy" class="footer-link"> Privacy </router-link>
                                <router-link to="/" class="footer-link"> About <em class="icon ni ni-lock-alt"></em></router-link>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="nk-footer-links">
                    <ul class="nav-sm">
                        <li class="nav-item">
                            <div class="d-flex flex-wrap justify-content-center mt5">
                                <a class="footer-link" href="https://twitter.com/CRYPTO_ETHANOL" target="_blank">
                                    <i class="bi bi-twitter"></i>
                                </a>
                                <a class="footer-link" href="https://t.me/+0_7Lbqd2gLowNGRi" target="_blank">
                                    <i class="bi bi-telegram"></i>
                                </a>
                                <a class="footer-link" href="https://discord.gg/J2CzgrXCW5" target="_blank">
                                    <i class="bi bi-discord"></i>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.navbar-nav > li {
    font-size: 20px;
}
</style>
