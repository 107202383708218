<template>
    <div class="nk-header nk-header-fixed is-light">
        <div>
            <div class="nk-header-wrap" style="height: 65px">
                <div class="nk-header-app-name">
                    <div class="">
                        <router-link to="/">
                            <img class="logo-light logo-img" src="/img/logo-light.png" alt="logo" />
                        </router-link>
                    </div>
                    <div class="">
                        <ul>
                            <li class="nk-menu-item">
                                <router-link to="/quests" class="nk-menu-link px-2 px-md-5">
                                    <span class="nk-menu-text fs-18px">{{ $t("Earn CyberXP") }}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="nk-header-tools p-0">
                    <ul class="nk-quick-nav">
                        <div :class="{'nk-sidebar-overlay': mobMenu}" @click="mobMenu = !mobMenu">
                            <div class="nk-header-menu" :class="{'nk-sidebar-active': mobMenu}" @click.stop>
                                <div class="nk-header-mobile">
                                    <div class="nk-header-brand">
                                        <router-link to="/" class="logo-link">
                                            <img class="logo-light logo-img" src="/img/logo-light.png" alt="logo" />
                                        </router-link>
                                    </div>
                                </div>
                                <ul class="nk-menu nk-menu-main ui-s2">
                                    <li class="nk-menu-item has-sub" :class="{active: subMenu1}">
                                        <a class="nk-menu-link nk-menu-toggle" @click="subMenu1 = !subMenu1">
                                            <span class="nk-menu-text">{{ $t("Marketplace") }}</span>
                                        </a>
                                        <ul class="nk-menu-sub">
                                            <li class="nk-menu-item">
                                                <router-link to="/store" class="nk-menu-link">
                                                    <span class="nk-menu-icon"><em class="icon ni ni-book"></em></span>
                                                    <span class="nk-menu-text">{{ $t("NFT Store") }}</span>
                                                </router-link>
                                            </li>
                                            <li class="nk-menu-item">
                                                <router-link to="" class="nk-menu-link">
                                                    <span class="nk-menu-icon"><em class="icon ni ni-book"></em></span>
                                                    <span class="nk-menu-text">{{ $t("Rentals") }}</span>
                                                    <em class="icon ni ni-lock-alt"></em>
                                                </router-link>
                                            </li>
                                            <li class="nk-menu-item">
                                                <router-link to="" class="nk-menu-link">
                                                    <span class="nk-menu-icon"><em class="icon ni ni-book"></em></span>
                                                    <span class="nk-menu-text">{{ $t("Subsctiptions") }}</span>
                                                    <em class="icon ni ni-lock-alt"></em>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nk-menu-item">
                                        <router-link to="/store/games" class="nk-menu-link">
                                            <span class="nk-menu-text">{{ $t("Games") }}</span>
                                        </router-link>
                                    </li>
                                    <li class="nk-menu-item">
                                        <router-link to="/news" class="nk-menu-link">
                                            <span class="nk-menu-text">{{ $t("News") }}</span>
                                        </router-link>
                                    </li>
                                </ul>
                                <!-- .nk-menu -->
                            </div>
                        </div>
                        <li v-if="user.data.prava >= 5" class="me-n1">
                            <router-link to="/panel" class="nk-quick-nav-icon d-none d-lg-block"><i class="icon ni bi-gear"></i></router-link>
                            <a @click="panelMenu = !panelMenu" class="nk-quick-nav-icon d-lg-none"><i class="icon ni bi-gear"></i></a>
                        </li>
                        <li v-if="user.auth" class="me-1 me-n1 text-white bolder">
                            <span style="font-size: 16px"><span style="font-size: 17px">$</span>{{ wallet.balance.toFixed(2) }}</span>
                        </li>
                        <li v-else class="me-n1">
                            <a @click="this.$modal('langModal')" class="nk-quick-nav-icon"><em class="icon ni ni-globe"></em></a>
                        </li>
                        <li v-if="user.auth" class="dropdown user-dropdown">
                            <Dropdown>
                                <a class="dropdown-toggle">
                                    <div class="user-toggle">
                                        <div class="user-avatar sm">
                                            <em class="icon ni ni-user-alt"></em>
                                        </div>
                                    </div>
                                </a>
                                <div class="dropdown-menu dropdown-menu-md dropdown-menu-end is-light">
                                    <div class="dropdown-inner user-card-wrap bg-lighter">
                                        <div class="user-card">
                                            <div class="user-info">
                                                <span class="lead-text">{{ user.data.mail }}</span>
                                            </div>
                                            <div class="user-action">
                                                <router-link to="/profile/t_setting" class="btn btn-icon me-n2"><em class="icon ni ni-setting"></em></router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dropdown-inner">
                                        <ul class="link-list">
                                            <li>
                                                <router-link to="/wallet"
                                                    ><em class="icon ni ni-wallet-alt"></em><span>{{ $t("Wallet") }}</span></router-link
                                                >
                                            </li>
                                            <li>
                                                <router-link to="/store/my"
                                                    ><em class="icon ni ni-img"></em><span>{{ $t("My collection") }}</span> <span v-if="user.data.nft > 0" class="user-balance p-0 ms-1" style="font-size: 16px">+{{ user.data.nft }}</span></router-link
                                                >
                                            </li>
                                            <li>
                                                <router-link to=""
                                                    ><em class="icon ni ni-coins"></em><span>{{ $t("Staking") }}</span
                                                    ><em class="icon ni ni-lock-alt" style="font-size: 14px"></em
                                                ></router-link>
                                            </li>
                                            <li>
                                                <router-link to="/affiliate"
                                                    ><em class="icon ni ni-users-fill"></em><span>{{ $t("Affiliate program") }}</span></router-link
                                                >
                                            </li>
                                            <li class="dropdown language-dropdown me-n1">
                                                <a @click="this.$modal('langModal')"
                                                    ><em class="icon ni ni-globe"></em> <span>{{ $t($i18n.locale == "ua" ? "Ukraine" : $i18n.locale == "ru" ? "Russian" : "English") }}</span></a
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="dropdown-inner">
                                        <ul class="link-list">
                                            <li>
                                                <a @click="logOut"
                                                    ><em class="icon ni ni-signout"></em><span>{{ $t("Sign out") }}</span></a
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Dropdown>
                        </li>
                        <li v-else class="dropdown">
                            <a @click="this.$modal('loginModal')" class="nk-quick-nav-icon"><em class="icon ni ni-signin"></em></a>
                        </li>
                        <li class="nk-menu-trigger d-lg-none ms-n1">
                            <a class="nk-nav-toggle nk-quick-nav-icon" @click="mobMenu = !mobMenu"><em class="icon ni ni-menu"></em></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <Modal :id="'langModal'" :size="'sm'">
        <ul class="language-list">
            <li>
                <a @click="lang('en')" class="language-item">
                    <span class="language-name">{{ $t("English") }}</span>
                </a>
            </li>
            <li>
                <a @click="lang('ru')" class="language-item">
                    <span class="language-name">{{ $t("Russian") }}</span>
                </a>
            </li>
            <li>
                <a @click="lang('ua')" class="language-item">
                    <span class="language-name">{{ $t("Ukraine") }}</span>
                </a>
            </li>
        </ul>
    </Modal>
    <Login v-if="!user.auth" />
    <panelMenu v-if="user.data.prava >= 5" :meta="this.$route.meta.panel" :show="panelMenu" />
</template>

<script>
import Login from "../../views/Auth.vue";
import panelMenu from "../panel/menu.vue";

export default {
    components: {Login, panelMenu},
    data() {
        return {
            date: new Date(),
            subMenu1: false,
            subMenu2: false,
            mobMenu: false,
            panelMenu: false,
            sidebarMenu: false,
            loginModal: false,
            email: "",
            password: "",
        };
    },
    mounted() {
        window.addEventListener("mousedown", this.handleMouseDown);
    },
    methods: {
        formatPrice(price) {
            if (typeof price === "string") {
                price = parseFloat(price);
            }
            return price.toFixed(4);
        },
        subMenuShow(id) {
            if (this.subMenu == id) {
                this.subMenu = "";
            } else if (this.subMenu != "" || this.subMenu != id) {
                this.subMenu = id;
            }
        },
        handleMouseDown(event) {
            if (this.subMenu != "") {
                this.subMenu = "";
            }
        },
        lang(lang) {
            this.$cookies.set("lang", lang, {
                path: "/",
                secure: true,
                sameSite: "strict",
            });

            if (Object.keys(this.$i18n.messages[lang]).length > 0) {
                this.$i18n.locale = lang;
            } else {
                this.$i18n.locale = "en";
                this.$i18n.silentFallbackWarn = true;
            }
            this.$modal("langModal");
        },
        async logOut() {
            this.$router.push("/");
            this.$cookies.set("token", "");
            this.$store.dispatch("userOut");
        },
    },
};
</script>
